// Generated by Framer (d31cd55)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {tgWZ5qakr: {hover: true}};

const serializationHash = "framer-jquQN"

const variantClassNames = {tgWZ5qakr: "framer-v-w7nbpl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, iGNORECursor, link, width, ...props}) => { return {...props, HcXl17phF: iGNORECursor ?? props.HcXl17phF, zizrr5g4i: link ?? props.zizrr5g4i} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;iGNORECursor?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, HcXl17phF, zizrr5g4i, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "tgWZ5qakr", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={zizrr5g4i} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-w7nbpl", className, classNames)} framer-19cmdzd`} data-border data-framer-cursor={HcXl17phF} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"tgWZ5qakr"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.1)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-061c2b9f-64af-493d-8868-31ff2263526e, rgb(15, 15, 15))", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} {...addPropertyOverrides({"tgWZ5qakr-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Web Development</motion.p><motion.p><motion.br className={"trailing-break"}/></motion.p></React.Fragment>} className={"framer-ic6zsl"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"l3dzocIIE"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-1ps7l37"} data-framer-name={"Blur"} layoutDependency={layoutDependency} layoutId={"wbOTfbWSr"} style={{background: "linear-gradient(180deg, var(--token-0d217399-5502-4e36-ad35-aff6664c8307, rgb(128, 255, 255)) /* {\"name\":\"color-3\"} */ -43%, rgb(0, 255, 255) 100%)", filter: "blur(100px)", opacity: 0, WebkitFilter: "blur(100px)"}} variants={{"tgWZ5qakr-hover": {opacity: 0.7}}}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jquQN.framer-19cmdzd, .framer-jquQN .framer-19cmdzd { display: block; }", ".framer-jquQN.framer-w7nbpl { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 372px; justify-content: center; overflow: hidden; padding: 100px; position: relative; text-decoration: none; width: 526px; will-change: var(--framer-will-change-override, transform); }", ".framer-jquQN .framer-ic6zsl { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-jquQN .framer-1ps7l37 { bottom: 0px; flex: none; height: 212px; left: 0px; overflow: hidden; position: absolute; width: 315px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jquQN.framer-w7nbpl { gap: 0px; } .framer-jquQN.framer-w7nbpl > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-jquQN.framer-w7nbpl > :first-child { margin-top: 0px; } .framer-jquQN.framer-w7nbpl > :last-child { margin-bottom: 0px; } }", ".framer-jquQN.framer-v-w7nbpl.hover .framer-1ps7l37 { overflow: visible; }", ".framer-jquQN[data-border=\"true\"]::after, .framer-jquQN [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 372
 * @framerIntrinsicWidth 526
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"nqiwoZYNo":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"HcXl17phF":"iGNORECursor","zizrr5g4i":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framern6dP5dnSI: React.ComponentType<Props> = withCSS(Component, css, "framer-jquQN") as typeof Component;
export default Framern6dP5dnSI;

Framern6dP5dnSI.displayName = "Tile/Project";

Framern6dP5dnSI.defaultProps = {height: 372, width: 526};

addPropertyControls(Framern6dP5dnSI, {HcXl17phF: {title: "[IGNORE] Cursor", type: ControlType.CustomCursor}, zizrr5g4i: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framern6dP5dnSI, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})